import React from 'react';
import '../styles/tailwind.css';

function App() {
    return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Image Watermarking App</h1>
      <button className="btn bg-blue-500 text-white">
        Watermark Images
      </button>
      <p className="mt-4">Status: working on it!</p>
    </div>
  );
}

export default App;
